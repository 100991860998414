@import url('https://fonts.googleapis.com/css?family=Montserrat:500,500i,700,700i&display=swap&subset=latin-ext');

@import "./bootstap-importer";

@import "./components/footer";
@import "./components/layout";


@import "./components/bulbs";
@import "./components/sequence";
@import "./components/registration";
@import "./utils/helpers";