.bulb-button {
    width: 40px;
    height: 40px;
    background:red;
    border-radius: 50%;
    $variants: (blue: blue, red:red, green: green);
    @each $scope, $color in $variants {
        &.variant-#{$scope} {
            background-color: $color;
            -webkit-box-shadow: 0px 0px 19px -4px $color;
            -moz-box-shadow: 0px 0px 19px -4px $color;
            box-shadow: 0px 0px 19px -4px $color;
        }
    }
}