.intro {
    // min-height: 500px;
    text-align: center;
    background-position: 15% center ;
    background-size: cover;
    padding-top: 150px;
    padding-bottom: 150px;
    strong {
        background: theme-color(primary);
        padding: 7px 13px;
        color: white;
        @media ( max-width: 400px ) {
            font-size: 30px !important;
        }
    }
}

.centering-spinner {
    display:flex;
    align-items: center;
    justify-content: center;
}

#mapa {
    padding-left: 0px;
    padding-right: 0px;
    background: lightgray;
    &.loading {
    }
    
}

.card-holder {
    .card {
        min-height: 300px;
        .card-body {
            &:last-child {
                flex-grow: 0;
                // background:red
            }
        }
    }
}

.intro-bg {
    background-image: url(/front-map.jpg);
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: center top;
    .card-holder {
        color: white;
        
        &:first-child {
            .card {
                background-image: url(/front-map-center.jpg);
                background-repeat: no-repeat;
                background-size: cover;
                background-position: center bottom;

            }
        }
        &:last-child {
            .card {
                background-image: url(/front-map-university.jpg);
                background-repeat: no-repeat;
                background-size: cover;
                background-position: center bottom;

            }
        }
    }
}