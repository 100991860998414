.justify-center {
    justify-content: center !important;
}
.fit-width {
    max-width: 100%;
    height: auto;
}

.fs-bigger {

    font-size: 1.5em;
    margin-bottom: 5px
}

.pseudo-button {
    display:inline-block;
    margin: 0 auto;
}

.text-transform-uppercase {
    text-transform: uppercase;
}