.card-text {
    &.collapsible {
        transition:max-height .3s ease-in-out;
        overflow: hidden !important;
        // max-height:0px;
    }
    &.collapsed {
        max-height: 0px;
    }
    &.expanded {
        max-height: 300px;
    }
}