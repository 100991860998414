.sequence-partial {
    display:flex;

    // padding: 15px 0px;

    .sequence-partial-intro {
        width: 40px !important;
        // flex-basis: 40px;
        margin-right: 20px;
    }
    .sequence-partial-text {
        flex-grow: 1;
    }
    .sequence-partial-number {
        width: 40px;
        height:40px;

        padding: 2px 10px;

        border-color: theme-color("primary");
        border-width: 3px;
        border-style: solid;
        border-radius: 50%;
        
        color: $white;// theme-color(primary);
        background-color: theme-color(primary);//white;

        font-weight: bold;
        font-size: 20px;
        text-align:center;
    }
}